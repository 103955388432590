<template>
  <div class="box">
    <div class="secondaryNavigation">
      <secondaryNavigation :list="list" name="收款码管理"></secondaryNavigation>
    </div>
    <div class="boxContent">
      <router-view/>
    </div>
  </div>
</template>
<script>
  import secondaryNavigation from '@/components/secondaryNavigation.vue';

  export default {
    name: 'proceedsCode',
    components: {
      secondaryNavigation
    },
    data() {
      return {
        list: [
          {
            url: '/proceedsCode/list',
            name: '收款码'
          }
        ]
      };
    }
  };
</script>
<style lang="scss" scoped>
  .boxContent {
    margin-top: 30px;
  }
</style>
